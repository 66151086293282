




































































































































































































































































































.notification-list-item:hover {
  box-shadow: 0px 4px 9px 2px rgba(0, 0, 0, 0.22);
  position: relative;
}

.notification-list-item.is-unread {
  background: #ecf5ff;
}

.notification-mark-all {
  position: absolute;
  top: -1px;
  right: 0;
  font-size: 0.75rem;
  font-family: Source Sans Pro, sans-serif;
}

.notification-sidebar {
  width: 28rem;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;

  &::v-deep {
    .p-sidebar-header {
      padding: 0.5rem;
    }
  }
}

.notification-content {
  font-size: 0.9rem;
}

.notification-date {
  font-size: 0.7rem;
  color: #707070;
}

.notification-list-item {
  cursor: pointer;
  text-decoration: none;
  color: #303133;
}

.notification-mark-all:enabled:focus {
  border: none !important;
  box-shadow: none !important;
}

.unread-count-inline {
  background-color: var(--red);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-left: 0.5rem;
}

.notification-tab-header {
  font-size: 0.9rem;
}

.notification-icon {
  font-size: 1rem;
  color: var(--white);
  padding: 0.3rem;
  border: 1px solid var(--white);
  border-radius: 1.3rem;
}

.dropdown::v-deep {
  width: 8rem;
  margin-top: -0.5rem;

  .p-dropdown-label.p-inputtext.p-inputtext {
    font-size: 0.875rem;
    padding: 0.5rem 0.5rem;
  }
}
