
























































































































:root {
  font-size: 16px;
  font-family: Source Sans Pro, Montserrat, Helvetica, sans-serif;
  --font-family: Source Sans Pro, Montserrat, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #303133;
  --primary-1: #409eff;
  --primary-2: #ecf5ff;
  --dark-grey: #2d3142;
  --rat-grey: #4f5d75;
  --brownish-grey: #707070;
  --red: #ef373e;
  --light-pink: #fdeced;
  --white: #ffffff;
  --butterscotch: #fcbf49;
  --egg-shell: #fff3c2;
  --leafy-green: #67c23a;
  --off-white: #f0faeb;
  --bluey-grey: #909399;
  --pale-grey-two: #ebeef5;
  --cloudy-blue: #c0c4cc;
  --pale-grey-three: #f2f6fc;
}

body {
  margin: 0;
  line-height: 1.25;
}

button {
  background: var(--white);
  border: none;
  font-size: 0.875rem;
}

p {
  margin-block-start: 0;
}

h3 {
  font-family: Roboto, sans-serif;
}

.p-component {
  font-family: Source Sans Pro, Montserrat, Helvetica, sans-serif !important;
}

#app {
  min-height: 100vh;
}

.main-header {
  width: 100%;
  height: 3.4rem;
  background-color: var(--dark-grey);
}
.main-container {
  width: 100%;
}

.mastersheet-select .top-columns-select {
  height: 2rem;
  border-radius: 4px;
  border: solid 1px #c0c4cd;
  background-color: var(--white);
  margin-left: 12px;
  font-size: 0.875rem;
  color: var(--brownish-grey);
}

.mastersheet-select .top-columns-select .p-dropdown-label.p-inputtext {
  padding: 0.35rem 0 0 0.594rem;
  color: var(--brownish-grey);
  font-size: 0.875rem;
}

.mastersheet-select
  .top-columns-select
  .p-multiselect-label-container
  .p-multiselect-label {
  padding: 0.35rem 0 0 0.594rem;
  color: var(--brownish-grey);
  height: 100%;
}

.p-formgrid.p-grid
  .p-multiselect.p-component.p-multiselect-chip
  .p-multiselect-label-container
  .p-multiselect-label {
  padding-top: 0.36rem;
  font-size: 0.9rem;
}

.mastersheet-select
  .top-columns-select
  .p-multiselect-panel.p-component
  .p-multiselect-items-wrapper
  .p-multiselect-items.p-component
  .p-multiselect-item {
  font-size: 0.875rem;
  color: var(--brownish-grey);
}

.p-button.top-button {
  height: 2rem;
  display: inline-block;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  font-size: 0.85rem;
  margin-right: 0.7rem;
}

.top-button:last-child {
  margin-right: 0;
}

.top-notification {
  height: 100%;
  margin-right: 19px;
  margin-left: 22px;
}

.top-user-info {
  margin-right: 1rem;
}

.p-menuitem-text {
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: var(--rat-grey);
}

.p-highlight .p-menuitem-text {
  font-weight: bold;
}

.p-tabmenu-nav {
  background-color: var(--pale-grey-two) !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background-color: var(--pale-grey-two) !important;
}

.data-table-header {
  font-family: Source Sans Pro, sans-serif;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--dark-grey) !important;
  background-color: var(--white) !important;
  border: none !important;
  padding-bottom: 0 !important;
}

.data-table-header-first {
  padding-left: 4rem !important;
}

.data-table-header-action {
  width: 9rem;
}

.data-table-body-center {
  text-align: center !important;
}

.data-table-body {
  font-family: Source Sans Pro, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #222222 !important;
  text-overflow: ellipsis;
}

.data-table-body .small-flag {
  margin: 0 !important;
}

.data-table-body-first {
  padding-left: 4rem !important;
}

.data-table-body-action {
  text-overflow: clip;
  text-align: right !important;
  padding-right: 5rem !important;
  width: 9rem;
}

.data-table-body-dense {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.p-filter-column {
  font-size: 0.875rem !important;
  padding: 0.3rem 0.3rem;
}

.p-filter-dropdown .p-dropdown-label.p-inputtext {
  font-size: 0.875rem !important;
}

.p-datatable .p-datatable-thead > tr > th.p-filter-column {
  padding: 0.5rem 1rem;
}

.p-datatable .p-column-header-content {
  padding: 0 0 0.875rem 0;
}

.p-button {
  font-size: 0.875rem;
  font-weight: 700;
}

.p-dropdown-item {
  font-size: 0.875rem;
}

.p-fluid .p-inputtext {
  margin: auto;
}

.row-view-button {
  padding: 0.25 0.5rem !important;
  font-size: 0.875rem;
  border-radius: 4px !important;
  border: solid 1px var(--bluey-grey) !important;
  background-color: var(--white) !important;
}

.row-view-button .p-button-label {
  font-family: Source Sans Pro, sans-serif;
  font-size: 0.875rem;
  color: #222222;
}

.row-edit-button {
  border: none !important;
  background-color: var(--white) !important;
  color: var(--primary-1) !important;
}

.row-view-button .p-button-icon {
  color: var(--primary-1);
}

.activities-card .p-card-body,
.activities-card .p-card-content,
.helps-card .p-card-body,
.helps-card .p-card-content,
.list-card .p-card-body,
.list-card .p-card-content {
  padding: 0 !important;
}

.edit-page-table-title {
  font-family: Source Sans Pro, sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
  padding-top: 2.3%;
}

.edit-page-table {
  height: 55vh !important;
  margin-top: 15px;
  border-radius: 4px;
  border: solid 1px var(--cloudy-blue);
  background: var(--white);
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
}

.form-field-error-message {
  font-family: Source Sans Pro, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--red);
}

.white-centered-table-header {
  background: var(--white) !important;
  text-align: center;
}

.dg-content-footer .dg-btn {
  float: right;
  margin-left: 0.5rem;
}

.p-accordion-header-link {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  background: none !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
}

.p-accordion-content {
  border-right: none !important;
  border-left: none !important;
}

.sidebar-nav {
  padding: 2.5rem;
  border-right: 1px solid #ebeef5;
  width: 15rem;
}

.sidebar-nav-link {
  color: #222222;
  text-decoration: none;
  margin-bottom: 1.75rem;
  cursor: pointer;
}

.sidebar-nav-link.router-link-active {
  color: #409eff;
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.313rem;
}

.exports-subpages-search-selects .p-chips,
.exports-subpages-search-selects .p-chips-multiple-container,
.exports-subpages-search-input,
.exports-subpages-search-input input {
  width: 100%;
}

.exports-subpages-search-selects .p-chips-token {
  background: #ecf5ff !important;
  color: #222 !important;
  padding: 0.7rem 1rem !important;
  border-radius: 22px !important;
  border: 1px solid #409eff !important;
}

.exports-subpages-search-input .p-inputtext,
.exports-subpages-search-selects .p-chips-multiple-container {
  border: none;
}

.exports-subpages-search-input input {
  padding: 1rem 2rem;
}

.exports-subpages-search-input {
  border-bottom: 1px solid #c0c4cc;
}

.exports-subpages-search {
  border: 1px solid #c0c4cc;
  border-radius: 4px;
}

.exports-subpages-search-selects {
  padding: 2rem;
}

.p-formgrid.p-grid .p-field.p-col {
  padding: 0 0.75rem;
}

.p-formgrid.p-grid .p-field.p-col,
.p-formgrid.p-grid .p-field.p-col input,
.p-dropdown-label.p-inputtext.p-inputtext {
  font-family: Source Sans Pro, sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.p-formgrid.p-grid .p-dropdown.p-component,
.p-formgrid.p-grid .p-inputtext.p-component,
.p-formgrid.p-grid .p-multiselect.p-component,
.p-formgrid.p-grid .vue-tel-input,
.p-formgrid.p-grid .vdpComponent.vdpWithInput input {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #c0c4cc;
  background-color: var(--white);
}

.p-dropdown-top-edit.p-component {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  border-radius: 4px;
  border: solid 1px var(--brownish-grey);
  background-color: var(--white);
}

.p-autocomplete-dropdown.p-button,
.p-autocomplete-dropdown.p-button:hover {
  background: none;
  color: #6c757d;
  border: 1px solid #6c757d;
  border-radius: 0 4px 4px 0;
}

.p-formgrid.p-grid .p-inputtext.p-component.p-autocomplete-input {
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.p-formgrid.p-grid
  .p-multiselect.p-component
  .p-multiselect-label-container
  .p-multiselect-label {
  padding-top: 12px;
}

.p-formgrid.p-grid .vdpComponent.vdpWithInput input {
  padding: 0 0.5rem;
}

.p-formgrid.p-grid .vdpComponent.vdpWithInput {
  width: 100%;
}

.p-formgrid.p-grid
  .p-inputtextarea.p-inputtext.p-component.p-inputtextarea-resizable {
  border-radius: 4px;
  border: solid 1px var(--brownish-grey);
  background-color: var(--white);
}

.form-input-container {
  margin-left: 2.7%;
  margin-top: 1.6%;
  margin-right: 4%;
  max-width: 76vw;
}

.form-button-group {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.export-button.p-button.p-component,
.account-button-set .p-button.p-component,
.form-button-group .p-buttonset .p-button.p-component {
  width: 99px;
  height: 48px;
  border-radius: 4px;
  border: solid 1px var(--brownish-grey);
  background-color: var(--white);
  font-family: Source Sans Pro, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  color: #303133;
  margin-left: 12px;
}

.export-button.p-button.p-component:enabled:hover,
.account-button-set .p-button.p-component:enabled:hover,
.form-button-group .p-buttonset .p-button.p-component:enabled:hover {
  color: var(--primary-1);
}

.export-button.p-button.p-component:enabled:active,
.account-button-set .p-button.p-component:enabled:active,
.form-button-group .p-buttonset .p-button.p-component:enabled:active {
  border-color: var(--primary-1) !important;
  background-color: var(--primary-1) !important;
  color: var(--primary-2);
}

.active-status-switch {
  height: 22px;
  border-radius: 15px;
  margin-top: 37px;
}

.active-status-switch .p-inputswitch-slider {
  border: solid 1px var(--cloudy-blue);
  background-color: var(--pale-grey-two) !important;
}

.active-status-switch .p-inputswitch-slider:before {
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background-color: var(--cloudy-blue) !important;
}

.active-status-switch.p-inputswitch-checked .p-inputswitch-slider {
  border: solid 1px var(--leafy-green);
  background-color: var(--off-white) !important;
}

.active-status-switch.p-inputswitch-checked .p-inputswitch-slider:before {
  width: 16px;
  height: 16px;
  margin-top: -8px;
  background-color: var(--leafy-green) !important;
}

.top-edit-block {
  padding-right: 4%;
  border-bottom: 1px solid var(--cloudy-blue);
}

.manage-edit-tabs {
  padding-top: 41px;
}

.manage-edit-tab {
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-grey);
  border-radius: 3px 3px 0 0;
}

.manage-edit-tabs .nav.nav-tabs {
  border-bottom: solid 1px var(--cloudy-blue);
}

.manage-edit-tab-active {
  background-color: #4962f3;
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: white !important;
  border-bottom-color: var(--white) !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.17);
}

.manage-edit-tab.right-most-tab {
  margin-right: 28px;
}

.notification {
  font-size: 1rem !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.05s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.row-count {
  margin-top: 0.8rem;
  margin-right: 1rem;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1rem;
  color: var(--brownish-grey);
  position: absolute;
  right: 1rem;
  top: -3rem;
  z-index: 2;
}

.nav-link.disabled.disabled.manage-edit-tab {
  color: var(--bluey-grey);
}
.date-range-selector {
  border-radius: 6px;
  border: solid 0.5px var(--brownish-grey);
  padding: 10px;
  background: white;
}

.no-padding.p-card .p-card-body {
  padding: 0;
}

.notifications .p-tabview-panels {
  padding: 0 !important;
}

.notifications .p-tabview-title {
  font-size: 0.85rem;
  font-family: "Source Sans Pro", sans-serif;
}

.notifications .p-tabview-nav-link {
  padding: 0.5rem 2.2rem !important;
}

.notifications .p-tabview-nav-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

.notifications .p-dropdown {
  background: #ebeef5;
  border: none;
  border-radius: 4px;
}

.notifications .p-dropdown-label {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}

.notifications .p-dropdown-trigger-icon {
  font-size: 0.5rem;
}

.analytic-dialog {
  height: 90% !important;
}

.analytic-dialog .p-dialog-content {
  padding: 0 !important;
  height: 100% !important;
  overflow: hidden;
}

.analytic-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.analytic-container .chart-container {
  width: 100%;
  position: relative;
  overflow: scroll;
}

.legend-square {
  width: 0.75rem;
  height: 0.75rem;
}

.chart-legend {
  font-size: 0.75rem;
}

.comments-category-select .p-dropdown-label {
  padding: 5px;
  font-size: 0.75rem;
}

.comments-category-select .p-dropdown-trigger-icon {
  font-size: 0.5rem;
}

.comments-category-select .p-dropdown-trigger {
  width: 2rem;
}

.notifications .notification-sidebar .p-sidebar-close {
  margin-right: 1rem;
  transform: scale(0.75);
}

.announcement-card .p-card-body {
  height: 0;
  flex-grow: 1;
}

.announcement-card .p-card-content {
  height: 100%;
}

.Overview .p-card {
  border-radius: 10px;
  margin: 0 0.6rem;
}

.top-user-info .tippy-arrow {
  display: none;
}

.top-user-info .tippy-box {
  background: #ecf5ff !important;
  border-radius: 10px !important;
}

.top-user-info .tippy-arrow::before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-bottom-color: transparent;
  border-style: solid;
}

.top-user-info .tippy-box[data-placement^="bottom"] > .tippy-arrow::before {
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: #ecf5ff !important;
  transform-origin: center bottom;
}

.p-button.p-button-main {
  color: white;
  background: #4962f3;
  border: 1px solid #4962f3;
}

.custom-button {
  // padding: 0.75rem 1rem 0.75rem 1rem !important;
  padding: 0.55rem 1.55rem 0.55rem 1.55rem !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

.custom-button-right-margin {
  margin-right: 0.75rem !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: var(--bluey-grey);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(144, 147, 153, 0.4);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--rat-grey);
}

.manage-list-view-button {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
  background-color: transparent;
}

.under-construction {
  height: 100%;
  width: 100%;
  padding-top: 5%;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(18.8%, 19.2%, 20%, 80%);
  -webkit-backdrop-filter: blur(0.5px);
  backdrop-filter: blur(0.5px);
}

.p-overflow-hidden .p-sidebar-mask.p-component-overlay {
  background: rgba(18.8%, 19.2%, 20%, 80%);
}

.p-overflow-hidden .p-sidebar-mask.p-component-overlay.p-sidebar-mask-leave {
  background-color: transparent;
}

.uppercase {
  text-transform: capitalize;
}

.p-divider.p-divider-horizontal:before {
  border-width: 2px;
}

.p-disabled {
  opacity: unset;
}

.p-formgrid.p-grid .vdpComponent.vdpWithInput input:focus {
  box-shadow: var(--focus-ring);
  outline: 0;
}
