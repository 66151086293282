











































.new-marker {
  font-size: 12px;
  font-family: Montserrat;
  font-style: italic;
  font-weight: bold;
  color: #4962f3;
  margin-left: 0.2rem;
}
